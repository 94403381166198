import React, { useState, useEffect } from "react";
import { Dashboard } from "./Dashboard";
import { VscDebugStart } from "react-icons/vsc";
import {
  BiEditAlt,
  BiSkipNext,
  BiUpArrow,
  BiUpArrowCircle,
} from "react-icons/bi";
import { MdOutlineNotes } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { ImCancelCircle } from "react-icons/im";
import {
  AiOutlinePlus,
  AiOutlinePlusCircle,
  AiOutlineStop,
} from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { AUTH_TOKEN, USER_DETAILS, baseURl } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import alarm from "../../image/alarm.mp3";
import AudioPlayerWithRepeat from "./AudioPlayerWithRepeat";
import ShiftComponent from "./components/ShiftComponent";
import Header from "./Header";
import { RecallModel } from "../../models/RecallModel";
import { PatientInModel } from "../../models/PatientInModel";
import { SkipRecallModel } from "../../models/SkipRecallModel";
import { BreakModel } from "../../models/BreakModel";
import { StartModel } from "../../models/StartModel";
import { EmergencyModel } from "../../models/EmergencyModel";
import { DescriptionModel } from "../../models/DescriptionModel";
import { PrintModel } from "../../models/PrintModel";
import { useNavigate } from "react-router-dom";
import Head from "./components/Head";

export const DashboardData = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [descriptions, setDescription] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [isModel, setIsModel] = useState(false);
  const [isEmergencyModel, setIsEmergencyModel] = useState(false);
  const [isSkipRecallModel, setIsSkipRecallModel] = useState(false);
  const [isRecallModel, setIsRecallModel] = useState(false);
  const [patientInModel, setPatientInModel] = useState(false);
  const [isRecallToken, setIsRecallToken] = useState(null);
  const [tokenType, setTokenType] = useState("pending");
  const [tokenValue, setTokenValue] = useState([]);
  const [skipPatients, setSkipPatients] = useState([]);
  const [patients, setPatients] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [is_break, setIsBreak] = useState(false);
  const [showBreakModal, setShowBreakModal] = useState(false);
  const [isStartButtonVisible, setStartButtonVisible] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [alarmShow, setAlarmShow] = useState(false);
  const [alarmOn, setAlarmOn] = useState(true);
  const [shifts, setShift] = useState([]);
  const [shiftDetails, setShiftDetails] = useState([]);
  const [startModel, setStartModel] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [printModal, setPrintModal] = useState(false);
  const [shift_id, setShiftId] = useState(
    localStorage.getItem("shift_id") ?? USER_DETAILS.active_shift_id ?? ""
  );
  const [subscriptionEnd, setSubscriptionEnd] = useState(
    localStorage.getItem("subscription_end") || 30
  );
  const [subscriptionDate, setSubscriptionDate] = useState(
    localStorage.getItem("subscription_date") || ""
  );
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const [fees, setFees] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gender: "Male",
    mobile: "",
    email: "",
  });
  const [permissions, setPermissions] = useState({
    start: 0,
    stop: 0,
    skip: 0,
    recall: 0,
    next: 0,
    startbreak: 0,
    stopbreak: 0,
    skipPatient: 0,
  });
  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const startOpenModel = (is_active) => {
    setIsActive(is_active);
    setStartModel(!startModel);
  };

  const handleToggleStartButton = async () => {
    setStartModel(!startModel);
    try {
      if (shift_id.length === 0 && isStartButtonVisible === false) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select your shift.",
        });
        return false;
      }
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/status`,
        { shift_id: shift_id, is_active: isActive },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        allPatient(shift_id);
        localStorage.setItem("shift_id", shift_id);
        setIsLoading(false);
        setStartButtonVisible(!isStartButtonVisible);
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
      setIsLoading(false);
    }
  };

  const offAlarm = () => {
    setAlarmOn(false);
  };

  useEffect(() => {
    if (seconds <= 0) {
      if (alarmShow === true) {
        setAlarmShow(false);
      }
      return;
    }

    if (seconds < 60) {
      if (alarmShow === false) {
        setAlarmShow(true);
      }
    }

    // Set up the timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [seconds]);

  //next
  const handleNextButtonClick = async () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    try {
      // Make the API call
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/patient/next`,
        { shift_id: shift_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        allPatient(shift_id);
        setIsLoading(false);
      } else {
        console.error("API request failed");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  //skip
  const handleSkipButtonClick = async () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    try {
      setIsLoading(true);
      const reqData = {
        token_number: isRecallToken,
        status: tokenType,
        shift_id: shift_id,
      };
      const response = await axios.post(
        `${baseURl}/doctor/patient/skip`,
        reqData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        allPatient(shift_id);
        setIsRecallToken(null);
        setIsRecallModel(false);
        setTokenType("panding");
      } else {
        console.error("API request failed");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
      setIsLoading(false);
    }
  };

  //rollback
  const handleRollBackButtonClick = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseURl}/doctor/patient/recall`,
        {
          token_number: isRecallToken,
          time: inputValue,
          status: tokenType,
          shift_id: shift_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data.data;
        allPatient(shift_id);
        setIsRecallModel(false);
        setInputValue(null);
        setIsLoading(false);
        setTokenType("panding");
      } else {
        console.error("API request failed");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
      setIsLoading(false);
    }
  };

  //Skip-rollback
  const handleSkipRollBackButtonClick = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseURl}/doctor/patient/skip-recall`,
        {
          token_number: isRecallToken,
          skip_number: inputValue,
          status: tokenType,
          shift_id: shift_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setIsSkipRecallModel(false);
        setInputValue(null);
        setIsLoading(false);
        setIsRecallToken(null);
        allPatient(shift_id);
        setTokenType("panding");
      } else {
        setIsRecallToken(null);
        setIsLoading(false);
        setIsSkipRecallModel(false);
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const handleBreakModel = () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setShowBreakModal(!showBreakModal);
  };
  const handleBreakButtonClick = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const breakUrl =
      inputValue != null
        ? `${baseURl}/doctor/start-break`
        : `${baseURl}/doctor/end-break`;

    setIsLoading(true);
    try {
      setAlarmShow(false);
      const response = await axios.post(
        breakUrl,
        { break_duration: inputValue, shift_id: shift_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        allPatient(shift_id);
        if (inputValue) {
          setShowBreakModal(!showBreakModal);
        }
        setInputValue(null);
        setIsLoading(false);
      } else {
        console.error("API request failed");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const allPatient = (shiftId) => {
    const id = shiftId.length === 0 ? shift_id : shiftId;
    axios
      .post(
        `${baseURl}/doctor/patient/all`,
        { shift_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      )
      .then((response) => {
        const data = response.data.data;
        setTotalFee(data.totalFee);
        setPatients(data.patients);
        setSkipPatients(data.skips);
        setTokenValue(data.current);
        setStartButtonVisible(data.start);
        setIsBreak(data.is_break);
        setTotal(data.total);
        setDescription(data.descriptions);
        setSeconds(data.break_countdown);
        setSubscriptionEnd(data.subscription_end);
        setSubscriptionDate(data.subscription_date);
        setIsSubscribe(data.is_subscribe);
        localStorage.setItem("subscription_date", data.subscription_date);
        localStorage.setItem("subscription_end", data.subscription_end);
        if (data.subscription_end == 0) {
          getPermission();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
        setIsLoading(false);
      });
  };

  const getPermission = async () => {
    try {
      const response = await axios.post(
        `${baseURl}/role/permissions`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      const permissions = response.data.data.permissions;
      const role_permissions = response.data.data.role_permissions;
      localStorage.setItem(
        "role_permissions",
        JSON.stringify(role_permissions)
      );
      localStorage.setItem("menu", JSON.stringify(permissions));
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const handleEmergency = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const data = {
      name: formData.name,
      dob: formData.dob,
      gender: formData.gender,
      mobile: formData.mobile,
      email: formData.email,
      shift_id: shift_id,
    };
    setIsLoading(true);
    try {
      axios
        .post(`${baseURl}/doctor/patient/emergency`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        })
        .then((response) => {
          //console.log("Token added successfully", response.data.message);
          Swal.fire({
            icon: "success",
            title: `Token Number: ${response.data.data.token_number}`,
            text: `Time - ${response.data.data.expected_time}`,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error adding token", error.response.data.message);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to add the token. Please try again.",
          });
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching the API:", error);
      setIsLoading(false);
    }
  };

  const fatchShiftList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/listShift`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        setShift(response.data.data);
        if (response.data.data.length == 1) {
          localStorage.setItem("shift_id", response.data.data[0].id);
          setShiftId(response.data.data[0].id);
          setShiftDetails(response.data.data[0]);
        }
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  useEffect(() => {
    allPatient(shift_id);
    fatchShiftList();
    if (userPer) {
      // console.log(userPer);
      const startPermission = userPer.some((perm) => perm.id === 8);
      const stopPermission = userPer.some((perm) => perm.id === 9);
      const nextPermission = userPer.some((perm) => perm.id === 10);
      const skipPermission = userPer.some((perm) => perm.id === 11);
      const recallPermission = userPer.some((perm) => perm.id === 15);
      const startbreakPermission = userPer.some((perm) => perm.id === 12);
      const stopbreakPermission = userPer.some((perm) => perm.id === 11);
      const skippataintPermission = userPer.some((perm) => perm.id === 11);
      // console.log(addPermission);
      setPermissions({
        start: startPermission,
        stop: stopPermission,
        next: nextPermission,
        skip: skipPermission,
        recall: recallPermission,
        startbreak: startbreakPermission,
        stopbreak: stopbreakPermission,
        skipPatient: skippataintPermission,
      });
    }
  }, []);

  const modelToggle = () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const type = isModel ? false : true;
    setIsModel(type);
  };

  const recallModelToggle = (value) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsRecallToken(value);
    setIsRecallModel(!isRecallModel);
    setTokenType("recalled");
  };

  const tokenInModelToggle = (patient = null) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    if (patientInModel === true) {
      allPatient(shift_id);
    }
    setPatientDetails(patient);
    setPatientInModel(!patientInModel);
    setIsSkipRecallModel(false);
  };

  const printOpenModel = () => {
    setPrintModal(false);
    setPatientDetails([]);
  };

  const skiprecallModelToggle = (value, type, patient) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const data = isSkipRecallModel ? false : true;
    setPatientDetails(patient);
    setIsRecallToken(value);
    setTokenType(type);
    setIsSkipRecallModel(data);
  };

  const emergencyModelToggle = () => {
    const type = isEmergencyModel ? false : true;
    setIsEmergencyModel(type);
  };

  const handleDescriptionSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseURl}/doctor/patient/description`,
        { description: inputValue },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        allPatient(shift_id);
        setInputValue(null);
        modelToggle();
        setIsLoading(false);
      } else {
        console.error("API request failed");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
      setIsLoading(false);
    }
  };

  const editToken = (token_id) => {
    navigate(`../admin/token/edit/${token_id}`);
  };

  const Token = ({ patient, recallModelToggle, type }) => {
    return (
      <div
        className="text-center text-white px-2 py-1 mb-2 me-1"
        style={{
          backgroundColor:
            patient.patient_in == 1
              ? patient.is_paid == ""
                ? "#bec719"
                : "#00a19a"
              : "gray",
          borderRadius: "5px",
        }}
        role="button"
      >
        <span
          onClick={() => recallModelToggle(patient.token_number, type, patient)}
        >
          {patient.token_number} - {patient?.patient?.name || "No Name"}
        </span>
        <span
          onClick={() => editToken(patient.id)}
          className="ms-2 text-warning"
          style={{ borderLeft: "1px solid white" }}
          title="Edit Patient"
          role="button"
        >
          {" "}
          <BiEditAlt style={{ color: "yellow" }} />
        </span>
        {patient.patient_in != 1 && patient.patient && (
          <span
            onClick={() => tokenInModelToggle(patient)}
            className="ms-2"
            style={{ borderLeft: "1px solid white" }}
            title="Patient In"
            role="button"
          >
            {" "}
            <BiUpArrowCircle style={{ color: "orange" }} />
          </span>
        )}
      </div>
    );
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const shiftChange = (e) => {
    setShiftId(e.target.value);
    allPatient(e.target.value);
  };

  return (
    <>
      <div className="dashboard">
        <div
          className="d-md-none"
          style={{
            width: "100%",
            backgroundColor: "black",
            color: "wheat",
            marginBottom: "60px",
          }}
        >
          <Header />
        </div>
        <div className="d-flex">
          <Dashboard />
          <div className="w-100">
            <div className="main-container">
              <hr className="d-block d-lg-none" />
              <Head />
              <div className="w-100">
                <div className="pb-2 px-3 h5">
                  Welcome <b>Dr. {USER_DETAILS.name}</b>
                </div>
                {is_break ? (
                  <>
                    <div className="row justify-content-center">
                      <div className="col-md-4 text-center">
                        <h1>Break Timer</h1>
                        <div className="mt-4">
                          <div
                            className="bg-danger pt-5"
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "50%",
                              marginLeft: "25%",
                            }}
                          >
                            <h1 className="text-white">
                              {formatTime(seconds)}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center px-2">
                      <div className="col-md-3 text-center mb-2">
                        <Button
                          className="btn btn-warning fs-5 fw-bold w-100"
                          type="button"
                          onClick={handleBreakModel}
                          disabled={isLoading}
                        >
                          <span className="fs-5 fw-bold">
                            <AiOutlinePlus /> Add time
                          </span>
                        </Button>
                      </div>
                      {alarmShow && alarmOn && (
                        <div className="col-md-3 text-center mb-2">
                          <AudioPlayerWithRepeat
                            src={alarm}
                            offAlarm={offAlarm}
                          />
                        </div>
                      )}
                      <div className="col-md-3 text-center mb-2">
                        {permissions.stopbreak > 0 && (
                          <Button
                            className="btn btn-success fs-5 fw-bold w-100"
                            type="button"
                            onClick={handleBreakButtonClick}
                            disabled={isLoading}
                          >
                            <span className="fs-5 fw-bold">
                              {isLoading && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              <AiOutlineStop /> Stop Break
                            </span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="container">
                    {subscriptionEnd <= 8 && (
                      <div className="alert alert-danger d-flex flex-wrap">
                        {subscriptionEnd <= 8 && subscriptionEnd >= 1 ? (
                          <h6 className="text-danger mb-0">
                            Your {!isSubscribe && "demo"} subscription will
                            expire on {subscriptionDate}.
                          </h6>
                        ) : (
                          <h6 className="text-danger mb-0">
                            Your {!isSubscribe && "demo"} subscription has
                            expired on {subscriptionDate}.{" "}
                            {!isSubscribe &&
                              "& You don't have subscription. Please subscribe to continue."}
                          </h6>
                        )}
                        <button
                          className="btn btn-primary py-0 ms-lg-2 mb-0"
                          onClick={() => navigate(`/admin/wallet`)}
                        >
                          Renew it
                        </button>
                      </div>
                    )}
                    <div className="mb-0">
                      {/* <div className="p-2 justify-content-end card mb-3">
                    <div className="d-flex">
                      <label
                        class="form-check-label mt-1 h5"
                        for="flexSwitchCheckChecked"
                      >
                        Single Shift Mode
                      </label>
                      <div class="form-check form-switch mx-4">
                        <input
                          class="form-check-input large"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          style={{ height: "1.6em", width: "3.8em" }}
                        />
                      </div>
                      <label
                        class="form-check-label mt-1 h5"
                        for="flexSwitchCheckChecked"
                      >
                        Multiple Shift Mode
                      </label>
                    </div>
                  </div> */}
                      <div className="row row-cols-xs-2">
                        <div className="col-xs-3 col-sm-6 col-md-6 col-lg-3">
                          <div className="d-flex align-items-center alert alert-success text-center py-2">
                            <p
                              className="h4 py-0 my-0"
                              style={{ width: "90px" }}
                            >
                              {total}
                            </p>
                            <p className="h6 py-0 my-0">Total Patient</p>
                          </div>
                        </div>
                        <div className="col-xs-3 col-sm-6 col-md-6 col-lg-3">
                          <div className="d-flex align-items-center alert alert-primary text-center py-2">
                            <p
                              className="h4 py-0 my-0"
                              style={{ width: "90px" }}
                            >
                              {patients.length}
                            </p>
                            <p className="h6 py-0 my-0">Pending Patient</p>
                          </div>
                        </div>
                        <div className="col-xs-3 col-sm-6 col-md-6 col-lg-3">
                          <div className="d-flex align-items-center alert alert-warning text-center py-2">
                            <p
                              className="h4 py-0 my-0"
                              style={{ width: "90px" }}
                            >
                              {skipPatients.length}
                            </p>
                            <p className="h6 py-0 my-0">Skip Patient</p>
                          </div>
                        </div>
                        <div className="col-xs-3 col-sm-6 col-md-6 col-lg-3">
                          <div className="d-flex align-items-center alert alert-danger text-center py-2">
                            <p
                              className="h4 py-0 my-0"
                              style={{ width: "90px" }}
                            >
                              {totalFee}
                            </p>
                            <p className="h6 py-0 my-0">Total Fees</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        {shifts.length > 1 && (
                          <div className="col-md-3 mb-2">
                            <ShiftComponent
                              shift_id={shift_id}
                              shiftChange={shiftChange}
                              shifts={shifts}
                              status={isStartButtonVisible}
                            />
                          </div>
                        )}
                        <div className="col-md-3 mb-2">
                          {isStartButtonVisible ? (
                            permissions.start > 0 && (
                              <button
                                className="btn btn-danger px-4 mb-3 form-control"
                                type="button"
                                onClick={() => startOpenModel(0)}
                                disabled={isLoading}
                              >
                                <span className="fs-5 fw-bold align-items-center">
                                  {isLoading && (
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                  )}
                                  <AiOutlineStop className="" /> Stop
                                </span>
                              </button>
                            )
                          ) : (
                            <>
                              {permissions.stop > 0 && (
                                <button
                                  className="btn btn-success px-4 mb-3 form-control"
                                  type="button"
                                  onClick={() => startOpenModel(1)}
                                  disabled={isLoading}
                                >
                                  <span className="fs-5 fw-bold align-items-center">
                                    {isLoading && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    <VscDebugStart className="" /> Start
                                  </span>
                                </button>
                              )}
                            </>
                          )}
                        </div>
                        {!isStartButtonVisible && (
                          <div className="col mb-2">
                            <div className="card mb-4">
                              <div
                                className="card-header d-flex justify-content-between"
                                style={{ backgroundColor: "#96C8A2" }}
                              >
                                <h5 className="fw-bold text-center">
                                  Patients List
                                </h5>
                                {/* <button
                              className="btn btn-warning btn-sm"
                              onClick={() => tokenInModelToggle([])}
                            >
                              Patient In
                            </button> */}
                              </div>
                              <div
                                className="card-body pe-0 ps-0"
                                style={{ backgroundColor: "#E0EEEE" }}
                              >
                                {isLoading ? (
                                  <p>Loading...</p>
                                ) : (
                                  <div
                                    className="w-100"
                                    style={{
                                      height: "250px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <div className="d-flex flex-wrap ps-2">
                                      {patients.map((patient) => (
                                        <div
                                          className="text-center text-white px-2 py-1 mb-2 me-1"
                                          style={{
                                            backgroundColor:
                                              patient.patient_in == 1
                                                ? patient.is_paid == ""
                                                  ? "#bec719"
                                                  : "#00a19a"
                                                : "gray",
                                            borderRadius: "5px",
                                          }}
                                          /* role="button"
                                       onClick={() =>
                                        tokenInModelToggle(patient)
                                      } */
                                        >
                                          {patient.token_number} -
                                          {patient?.patient?.name || "No Name"}
                                          <span
                                            role="button"
                                            onClick={() =>
                                              editToken(patient.id)
                                            }
                                            className="ms-2"
                                            style={{
                                              borderLeft: "1px solid white",
                                            }}
                                            title="Edit Patient"
                                          >
                                            {" "}
                                            <BiEditAlt
                                              style={{ color: "yellow" }}
                                            />
                                          </span>
                                          {patient.patient_in != 1 &&
                                            patient.patient && (
                                              <span
                                                role="button"
                                                onClick={() =>
                                                  tokenInModelToggle(patient)
                                                }
                                                className="ms-2"
                                                style={{
                                                  borderLeft: "1px solid white",
                                                }}
                                                title="Patient In"
                                              >
                                                {" "}
                                                <BiUpArrowCircle
                                                  style={{ color: "orange" }}
                                                />
                                              </span>
                                            )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {isStartButtonVisible && (
                      <div className="row">
                        <div className="col-md-12 col-lg-7 col-sm-12">
                          <div className="card mb-4">
                            <div
                              className="card-header"
                              style={{ backgroundColor: "#96C8A2" }}
                            >
                              <h5 className="fw-bold text-center">
                                Current Token
                              </h5>
                            </div>
                            <div
                              className="card-body "
                              style={{ backgroundColor: "#E0EEEE" }}
                            >
                              {/* =================================== desktop View ================================= */}

                              <div className="d-none d-lg-block d-md-block">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                  {!is_break && (
                                    <div className="">
                                      {permissions.next > 0 && (
                                        <button
                                          className="btn btn-primary mb-3 mt-2"
                                          type="button"
                                          onClick={handleNextButtonClick}
                                          disabled={isLoading}
                                          style={{ width: "160px" }}
                                        >
                                          <span className="fs-5 fw-bold">
                                            {isLoading && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            <BiSkipNext /> Next
                                          </span>
                                        </button>
                                      )}
                                      <br />
                                      {permissions.skip > 0 && (
                                        <button
                                          className="btn btn-warning mb-3"
                                          type="button"
                                          onClick={handleSkipButtonClick}
                                          disabled={isLoading}
                                          style={{ width: "160px" }}
                                        >
                                          <span
                                            className="fs-5 fw-bold"
                                            onClick={() =>
                                              setIsRecallToken(null)
                                            }
                                          >
                                            {isLoading && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            <ImCancelCircle /> Skip
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  )}
                                  <div className="token-view">
                                    <div
                                      className="text-center pb-3 px-3"
                                      style={{
                                        backgroundColor: "grey",
                                        borderRadius: "50px",
                                      }}
                                    >
                                      {!is_break ? (
                                        <div>
                                          <span
                                            className="text-center"
                                            style={{ fontSize: "2.5rem" }}
                                          >
                                            {tokenValue?.token_number}
                                          </span>
                                          <h4 className="fs-lg-4 h4 py-0 my-0">
                                            {tokenValue?.patient?.name ||
                                              "No Name"}
                                          </h4>
                                          <small>
                                            {tokenValue?.patient?.email}
                                          </small>
                                        </div>
                                      ) : (
                                        <span style={{ fontSize: "2rem" }}>
                                          It's Break
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="token-button d-flex flex-column">
                                    {!is_break && (
                                      <>
                                        {permissions.recall > 0 && (
                                          <button
                                            className="btn btn-info mb-3 fs-5 fw-bold"
                                            type="button"
                                            onClick={() =>
                                              recallModelToggle(
                                                tokenValue?.token_number
                                              )
                                            }
                                            disabled={isLoading}
                                            style={{ width: "160px" }}
                                          >
                                            <span className="fs-5 fw-bold">
                                              {isLoading && (
                                                <span className="spinner-border spinner-border-sm mr-1"></span>
                                              )}
                                              <IoMdRefresh />
                                              Recall
                                            </span>
                                          </button>
                                        )}
                                        {/* <button
                                      className="btn btn-info mb-3 fs-5 fw-bold"
                                      type="button"
                                      onClick={() => tokenInModelToggle()}
                                      disabled={isLoading}
                                      style={{ width: "160px" }}
                                    >
                                      <span className="fs-5 fw-bold">
                                        {isLoading && (
                                          <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        <IoMdRefresh />
                                        Patient In
                                      </span>
                                    </button> */}
                                      </>
                                    )}
                                    {is_break ? (
                                      <>
                                        <button
                                          className="btn btn-danger fs-5 fw-bold mt-2 mb-1"
                                          type="button"
                                          onClick={handleBreakModel}
                                          disabled={isLoading}
                                        >
                                          <span className="fs-5 fw-bold">
                                            <MdOutlineNotes /> Add more time
                                          </span>
                                        </button>
                                        <button
                                          className="btn btn-success fs-5 fw-bold"
                                          type="button"
                                          onClick={handleBreakButtonClick}
                                          disabled={isLoading}
                                          style={{ width: "160px" }}
                                        >
                                          <span className="fs-5 fw-bold">
                                            {isLoading && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            <MdOutlineNotes />
                                            Stop Break
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      permissions.skip > 0 && (
                                        <button
                                          className="btn btn-danger fs-5 fw-bold"
                                          type="button"
                                          onClick={handleBreakModel}
                                          disabled={isLoading}
                                          style={{ width: "160px" }}
                                        >
                                          <span className="fs-5 fw-bold">
                                            <MdOutlineNotes /> Start Break
                                          </span>
                                        </button>
                                      )
                                    )}
                                  </div>
                                </div>
                                {/* <div className="text-center mt-4">
                            <button
                              className="btn btn-danger px-5"
                              type="button"
                              onClick={() => emergencyModelToggle()}
                            >
                              <span className="fs-5 fw-bold m">
                                <TbEmergencyBed /> Emergency
                              </span>
                            </button>
                          </div> */}
                              </div>
                              {/* =================================== end desktop View ================================= */}

                              {/* =================================== mobile View ================================= */}
                              <div className="d-block d-lg-none d-md-none">
                                <div className="">
                                  <div className="d-flex justify-content-between">
                                    {is_break ? (
                                      <>
                                        <button
                                          className="btn btn-danger fs-2 fw-bold mb-4 mt-2"
                                          type="button"
                                          onClick={handleBreakModel}
                                          disabled={isLoading}
                                        >
                                          <span className="fs-5 fw-bold">
                                            <MdOutlineNotes /> Add more time
                                          </span>
                                        </button>
                                        <button
                                          className="btn btn-success fw-bold"
                                          type="button"
                                          onClick={handleBreakButtonClick}
                                          disabled={isLoading}
                                        >
                                          <span className="fs-5 fw-bold">
                                            {isLoading && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            <MdOutlineNotes />
                                            Stop Break
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className="btn btn-danger mb-4 mt-2 fs-6"
                                        type="button"
                                        onClick={handleBreakModel}
                                        disabled={isLoading}
                                        style={{ width: "160px" }}
                                      >
                                        <span className="fw-bold">
                                          <MdOutlineNotes /> Start Break
                                        </span>
                                      </button>
                                    )}
                                    {!is_break && (
                                      <>
                                        <button
                                          className="btn btn-info mb-4 mt-2 fs-5 fw-bold px-lg-5"
                                          type="button"
                                          onClick={() =>
                                            recallModelToggle(
                                              tokenValue?.token_number
                                            )
                                          }
                                          disabled={isLoading}
                                          style={{ width: "160px" }}
                                        >
                                          <span className="fs-5 fw-bold">
                                            {isLoading && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            <IoMdRefresh />
                                            Recall
                                          </span>
                                        </button>
                                        {/* <button
                                      className="btn btn-info mb-4 mt-2 fs-6 fw-bold px-lg-5"
                                      type="button"
                                      onClick={() => tokenInModelToggle()}
                                      disabled={isLoading}
                                      style={{ width: "160px" }}
                                    >
                                      <span className="fs-6 fw-bold">
                                        {isLoading && (
                                          <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        <IoMdRefresh />
                                        Patient In
                                      </span>
                                    </button> */}
                                      </>
                                    )}
                                  </div>

                                  <div className="token-view d-flex justify-content-center">
                                    <div
                                      className="px-lg-3 px-3 py-2 mb-3"
                                      style={{
                                        backgroundColor: "grey",
                                        borderRadius: "25px",
                                        width: "250px",
                                      }}
                                    >
                                      {!is_break ? (
                                        <span className="d-flex align-items-center">
                                          <span
                                            className="text-center"
                                            style={{
                                              fontSize: "2.5rem",
                                              width: "60px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {tokenValue?.token_number}
                                          </span>
                                          <div>
                                            <h4 className="fs-lg-4 fs-5">
                                              {tokenValue?.patient?.name}
                                            </h4>
                                            <p className="mb-0 pb-0">
                                              {tokenValue?.patient?.email}
                                            </p>
                                          </div>
                                        </span>
                                      ) : (
                                        <span style={{ fontSize: "2rem" }}>
                                          It's Break
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    {!is_break && (
                                      <button
                                        className="btn btn-warning mb-4 mt-2 px-4"
                                        type="button"
                                        onClick={handleSkipButtonClick}
                                        disabled={isLoading}
                                        style={{ width: "160px" }}
                                      >
                                        <span
                                          className="fs-6 fw-bold"
                                          onClick={() => setIsRecallToken(null)}
                                        >
                                          {isLoading && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                          )}
                                          <ImCancelCircle /> Skip
                                        </span>
                                      </button>
                                    )}
                                    {!is_break && (
                                      <button
                                        className="btn btn-primary mb-4 mt-2 px-4"
                                        type="button"
                                        onClick={handleNextButtonClick}
                                        disabled={isLoading}
                                        style={{ width: "160px" }}
                                      >
                                        <span className="fs-6 fw-bold">
                                          {isLoading && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                          )}
                                          <BiSkipNext /> Next
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                                {/* <div className="text-center mt-4">
                            <button
                              className="btn btn-danger px-5"
                              type="button"
                              onClick={() => emergencyModelToggle()}
                            >
                              <span className="fs-5 fw-bold m">
                                <TbEmergencyBed /> Emergency
                              </span>
                            </button>
                          </div> */}
                              </div>

                              {/* =========================== end mobile view */}
                            </div>
                          </div>
                          <div className="card mb-4">
                            <div
                              className="card-header d-flex justify-content-between"
                              style={{ backgroundColor: "#96C8A2" }}
                            >
                              <h5 className="fw-bold text-center">
                                Description
                              </h5>
                              <AiOutlinePlusCircle
                                className="fw-bold fs-3"
                                onClick={modelToggle}
                                role="button"
                              />
                            </div>
                            <div className="card-body">
                              <div
                                className="card-title text-center"
                                style={{ backgroundColor: "#96C8A2" }}
                              ></div>
                              {descriptions.map((item, index) => (
                                <p key={index}>
                                  {index + 1} - {item.message}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12">
                          <div className="card mb-4">
                            <div
                              className="card-header d-flex justify-content-between"
                              style={{ backgroundColor: "#96C8A2" }}
                            >
                              <h5 className="fw-bold text-center">
                                Patients List
                              </h5>
                              {/* <button
                            className="btn btn-warning btn-sm"
                            onClick={() => tokenInModelToggle([])}
                          >
                            Patient In
                          </button> */}
                            </div>
                            <div
                              className="card-body pe-0 ps-0"
                              style={{ backgroundColor: "#E0EEEE" }}
                            >
                              {isLoading ? (
                                <p>Loading...</p>
                              ) : (
                                <div
                                  className="w-100"
                                  style={{ height: "250px", overflowY: "auto" }}
                                >
                                  <div className="d-flex flex-wrap ps-2">
                                    {patients.map((patient, index) => (
                                      <Token
                                        key={index}
                                        patient={patient}
                                        recallModelToggle={
                                          skiprecallModelToggle
                                        }
                                        type="pending"
                                        status="emergency"
                                      />
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="card mb-4">
                            <div
                              className="card-header"
                              style={{ backgroundColor: "#96C8A2" }}
                            >
                              <h5 className="fw-bold text-center">
                                Skip Patients Lists
                              </h5>
                            </div>
                            <div
                              className="card-body"
                              style={{ backgroundColor: "#E0EEEE" }}
                            >
                              <div className="">
                                {isLoading ? (
                                  <p>Loading...</p>
                                ) : (
                                  permissions.skipPatient > 0 && (
                                    <div className="ps-2 d-flex flex-wrap">
                                      {skipPatients.map((patient, index) => (
                                        <Token
                                          key={index}
                                          patient={patient}
                                          recallModelToggle={
                                            skiprecallModelToggle
                                          }
                                          type="skip"
                                        />
                                      ))}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="card mt-4">
                      <div
                        className="card-header"
                        style={{ backgroundColor: "#96C8A2" }}
                      >
                        <h5 className="fw-bold text-center">
                          Emergency Patients List
                        </h5>
                      </div>
                      <div
                        className="card-body"
                        style={{ backgroundColor: "#E0EEEE" }}
                      >
                        <div className="d-flex flex-wrap justify-content-center">
                          <Token value="2" />
                        </div>
                      </div>
                    </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModel === true && (
        <DescriptionModel
          show={isModel}
          handleSubmit={handleDescriptionSubmit}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={modelToggle}
          isLoading={isLoading}
        />
      )}
      {isRecallModel === true && (
        <RecallModel
          show={isRecallModel}
          handleSubmit={handleRollBackButtonClick}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={recallModelToggle}
          isLoading={isLoading}
        />
      )}
      {patientInModel === true && (
        <PatientInModel
          show={patientInModel}
          patient={patientDetails}
          setPrintModal={setPrintModal}
          setPatientInModel={setPatientInModel}
          isLoading={isLoading}
          allPatient={allPatient}
          shift_id={shift_id}
          setPatientDetails={setPatientDetails}
        />
      )}
      {showBreakModal === true && (
        <BreakModel
          show={showBreakModal}
          handleSubmit={handleBreakButtonClick}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={handleBreakModel}
          isLoading={isLoading}
        />
      )}
      {isSkipRecallModel === true && (
        <SkipRecallModel
          show={isSkipRecallModel}
          handleSubmit={handleSkipRollBackButtonClick}
          inputValue={inputValue}
          handleSkipSubmit={handleSkipButtonClick}
          setInputValue={setInputValue}
          modelToggle={skiprecallModelToggle}
          isLoading={isLoading}
          tokenType={tokenType}
          tokenInModelToggle={tokenInModelToggle}
          patient={patientDetails}
        />
      )}
      {isEmergencyModel === true && (
        <EmergencyModel
          show={isEmergencyModel}
          handleSubmit={handleEmergency}
          formData={formData}
          handleInputChange={handleInputChange}
          modelToggle={emergencyModelToggle}
          isLoading={isLoading}
        />
      )}
      {startModel === true && (
        <StartModel
          show={startModel}
          handleSubmit={handleToggleStartButton}
          modelToggle={startOpenModel}
          isStartButtonVisible={isStartButtonVisible}
          isLoading={isLoading}
        />
      )}

      {printModal === true && (
        <PrintModel
          show={printModal}
          modelToggle={printOpenModel}
          patient={patientDetails}
        />
      )}
    </>
  );
};
